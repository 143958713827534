import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const PrivacyPage = () => (
  <Layout>
        <SEO title="About" />
        <Container className="pad_header">
            <h1 className="header_title txt_align_center ptb_35">Jus-Pharma Privacy Policy</h1>
            <div className="txt_section">
                <p>JusGlobal LLC d/b/a Jus-Pharma (“Jus-Pharma,” “we,” or “us”) respects the privacy of our
                customers and others who use our website, mobile applications, products, and services. This Privacy
                Policy applies to your access and use of the https://jusglobal.com/jus-pharma website (the
                “Website”) and the Jus-Pharma applications (each, an “App”). The Website and the Apps are
                collectively referred to in this Privacy Policy as the “Platform.” This Privacy Policy is incorporated
                into and is subject to the Jus-Pharma Terms of Use. Within the Platform, we may provide additional
                notices related to the privacy or protection of your data that are specific to certain practices or
                features of the Platform. The terms of those notices are to be read and understood in conjunction
                with this Privacy Policy.</p>
                <p>This Privacy Policy is provided to describe to you how Jus-Pharma may collect, use, protect, and in
                some cases, disclose, information that relates to you or can be used to identify you (“Personal
                Information”) that we may collect through the Platform. The provisions of this Privacy Policy apply
                only to Personal Information collected via the Platform. This Privacy Policy places no restriction on
                the use of non-personally identifiable information Jus-Pharma collects from your use of the
                Platform, or de-identified or aggregated information</p>
                <p>By using the Platform, and in particular when you submit information through the Platform, you
                acknowledge that you understand and agree to the terms of this Privacy Policy. Jus-Pharma may
                change this policy from time to time without notice by updating the Privacy Policy on the Platform.
                Your use of the Platform after any change indicates your continued understanding of, and agreement
                to, the revised policy, so please review the Privacy Policy periodically to be sure you are familiar
                with our current policy, especially before you provide any Personal Information.</p>
            </div>
            
            <div className="txt_section">
                <h3 className="sub_title">Information We Collect</h3>
                <p>Jus-Pharma collects and uses Personal Information that you provide to us voluntarily, such as when
                you register for an account or submit a question or comment online. You do not have to provide
                these types of Personal Information to visit the Website or download any of our Apps, but JusPharma and its customers may be limited in the ability to provide certain information to you in that
                case. We also collect certain Personal Information based on your interaction with the Platform.</p>
                <p className="fw_500">The types of Personal Information we collect include:</p>
                <ul>
                    <li><p><span className="text_underline">Health information.</span> We collect health information that you provide when you use the
                    Platform as a participant in a clinical trial or research study. This might include information
                    about your diagnosis, medical history, treatment history, and experience with the products of
                    pharmaceutical companies and medical device manufacturers. We recognize that this
                    information is especially sensitive.</p></li>
                    <li><p><span className="text_underline">Internet or other similar network activity.</span> Certain technical information about your browser
                    and computer is automatically collected when you access the Platform.</p></li>
                    <li><p><span className="text_underline">Identifiers.</span> We or our service providers collect your Internet Protocol (IP) address and device
                    identifiers, as well as contact information you provide when you interact with us, such as
                    your name, address, telephone number or email address.</p></li>
                    <li><p><span className="text_underline">Your contact details.</span> Your name, email address and other contact details, which you may
                    provide to us.</p></li>
                    <li><p><span className="text_underline">Location Information.</span> This may include the physical location of your device. This
                    information may be captured in a variety of ways, including through the use of tracking
                    technologies such as Cookies (which are small files containing a string of alphanumeric
                    characters) that we send to your computer or mobile device, web beacons or clear GIFs
                    (which are small strings of code that provide a means to deliver a GIF for tracking purposes)
                    that we embed in the Platform or in emails we send to you, and similar technologies.</p></li>
                    <li><p><span className="text_underline">Transaction Information.</span> You may provide and we may collect Personal Information when
                    you fill out forms or fields while using the Platform and when you contact us or request
                    information about us or the Platform, as well as when you make purchases via the Platform.</p></li>
                </ul>
                <p className="fw_500">We collect this information from the following sources:</p>
                <ul>
                    <li><p><span className="text_underline">Directly from you.</span> We collect information that you directly provide us, such as your contact
                    information and health information, when you interact with the Platform.</p></li>
                    <li><p><span className="text_underline">Indirectly from you.</span> We collect some information, like your IP address, by observing your
                    interactions on our Website.</p></li>
                    <li><p><span className="text_underline">From a third party.</span> We collect information that you authorize other parties (such as your
                    healthcare provider) to share with us.</p></li>
                </ul>
                <p className="fw_500">If you are a medical professional and disclose Personal Information about a patient through
                the Platform, you and you alone bear the sole obligation and responsibility of obtaining any
                patient consents and/or authorization for such disclosure that may be required under federal,
                state or other applicable data protection law.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">How We Use Your Information</h3>
                <p>Jus-Pharma may use your Personal Information to provide services to our customers (including
                    pharmaceutical companies and medical device manufacturers that engage us to facilitate clinical
                    trials and research studies), to respond to your inquiry, to develop a business or professional
                    relationship with you, to comply with applicable legal or regulatory requirements such as to followup and report on adverse events, to help our customers comply with their legal or regulatory
                    obligations(such as to respond to your requests to access or delete the information they have about
                    you), to enforce our rights such as under the Terms of Use, or for everyday business purposes such
                    as website management, research and product development.</p>
            </div>
            <div className="txt_section">
                <h3 className="sub_title">How We Share Your Information</h3>
                <p>Jus-Pharma does not sell any of your Personal Information.</p>
                <p>Jus-Pharma may share your Personal Information with its agents, contractors or partners in
                    connection with services that these individuals or entities perform for, or on behalf of, Jus-Pharma in
                    connection with the uses described above. These individuals or companies are restricted from using
                    the Personal Information in any way other than to provide such services.</p>
                <p>Personal Information may also be transferred in connection with a merger or other business
                    combination or restructuring, bankruptcy, transfer of assets, or to our successors in interest.</p>
                <p>Additionally, we may disclose your information to law enforcement, regulators, governmental
                    authorities, courts, tax authorities, attorneys, and others for the following reasons:</p>
                <ul>
                    <li><p>If we are required to do so by law or in the good-faith belief that such action is necessary to
                    comply with law (including copyright law), in response to a court order, judicial or other
                    government subpoena or warrant, or to otherwise cooperate with law or government
                    enforcement activity.</p></li>
                    <li><p>In our good faith belief that doing so is appropriate or necessary to take precautions against
                    liability and/or to investigate and defend ourselves against any third party claims or
                    allegations.</p></li>
                    <li><p>To protect Jus-Pharma from fraudulent, abusive, or unlawful uses or activity and to protect
                    the security or integrity of the Platform and the rights, property, or safety of Jus-Pharma, our
                    customers and users, or others.</p></li>
                </ul>
                <p>Where appropriate, we may notify users about legal requests unless: (i) providing notice is
                    prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we
                    believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an
                    individual or group, or create or increase a risk of fraud upon Jus-Pharma property, its users, and the
                    Platform. In instances where we comply with legal requests without notice for these reasons, we will
                    attempt to notify that user about the request after the fact where appropriate and where we determine
                    in good faith that we are no longer prevented from doing so.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Chats and Forums</h3>
                <p>When you register to use the Platform, you may voluntarily share information through our private
                    chat function or through forums made available to you via the Platform. You may have the option to
                    communicate via text, video and audio. To use these features, you may be asked to enable settings
                    that allow others access to your device, such as access to your camera, microphone, or photos.
                    Please note that any information you share in areas that are accessible to other Platform users may
                    be disclosed outside of the Platform. Jus-Pharma is not responsible for the actions of other
                    organizations or Platform users, including their disclosures of information that you voluntarily share
                    in forums and chatrooms that are accessible to other Platform users.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Tracking Technologies</h3>
                <p>We may use a variety of cookies and third-party technologies to understand how you use and
                    navigate the Platform. These may include Session, Persistent, and Flash Cookies and data analytics
                    tools such as Google Analytics. A Session Cookie is temporary and disappears after you close your
                    browser. A Persistent Cookie remains after you close your browser and may be used by your 
                    browser on subsequent visits to the Website, including to remember your previous actions.
                    Persistent Cookies can be removed. You may be able to configure your browser to accept or reject
                    all Cookies or to notify you when a Cookie is set. Each browser is different. Please review your web
                    browser “Help” file to learn the proper way to modify your Cookie settings. If you delete, or choose
                    not to accept, Cookies from the Website, you may not be able to utilize the features of the Platform
                    to their fullest potential.</p>
                <p>Please note that “flash” Cookies are different from “browser” Cookies because of the way in which
                    flash Cookies capture and store data. Your browser’s Cookie management tools will not remove
                    flash Cookies. To learn how to manage privacy and storage settings for Flash Cookies click here: 
                    <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#11
                    7118"> http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#11
                    7118</a>. We may use flash Cookies to provide some content such as video or animation.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Your Rights and Choices</h3>
                <p>You may, of course, decline to share certain information with us, in which case we may not be able
                    to provide to you some of the features and functionality of the Platform. You can limit the
                    information you provide to Jus-Pharma. Participation in and interaction with various Platform
                    features is voluntary.</p>
                <p>Once you have registered for a Jus-Pharma account, you may update, correct, or delete your profile
                    information and preferences at any time by accessing your account preferences page through the
                    Platform.</p>
                <p>If you have not created an account and you would like for us to delete your information, please send
                    us a request at info@Jus-Pharma.com.</p>
                <p>If you do not wish to receive promotional communications, you can opt-out through links provided
                    in email messages, by updating your account preferences, or by contacting us at info@JusPharma.com. This opt-out will not apply to emails related to the completion of your registration,
                    correction of user data, change of password, and other similar communications essential to your
                    transactions. Your changes will be reflected promptly in active databases but we may retain
                    information you submit for a variety of purposes, including backups and archiving, prevention of
                    fraud and abuse, and analytics.</p>
                <p>Depending on the jurisdiction in which you reside, you may have the right to access, correct, delete,
                    or restrict use of certain Personal Information covered by this Privacy Policy. Depending on the
                    jurisdiction, you may also have the right to request that we refrain from processing your Personal
                    Information. Please bear in mind that if you exercise such rights this may affect our ability to
                    provide our products and services. For inquiries about your Personal Information, please contact us
                    by e-mail at info@Jus-Pharma.com, or through any of the contact information provided below.
                    While Jus-Pharma will make reasonable efforts to accommodate your request, we also reserve the
                    right to impose certain restrictions and requirements on such requests, if allowed or required by
                    applicable laws. Please note that it may take some time to process your request, consistent with
                    applicable law.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">For Participants in Clinical Trials and Research Studies</h3>
                <p>We process information on behalf of pharmaceutical companies and medical device manufacturers,
                    and we have processes in place to help them respond to requests from individuals to exercise data
                    protection rights that apply under applicable law. Please reach out to your contact at the applicable
                    company directly with questions about how you can exercise your data protection rights as a
                    participant.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Security</h3>
                <p>Please note that while Jus-Pharma has endeavored to create a secure and reliable Platform for users,
                    the confidentiality of any communication or material, including any Personal Information contained
                    within it, transmitted to/from Jus-Pharma via the Platform cannot be guaranteed. There is always
                    some risk involved when submitting data over the Internet. Jus-Pharma cannot guarantee that the
                    Platform is 100% safe from illegal tampering or “hacking.” Once received, Jus-Pharma maintains
                    reasonable administrative, technical, and physical safeguards to protect your Personal Information,
                    however, no system is completely secure or error-free. Jus-Pharma does not, and cannot, guarantee
                    the complete security of your Personal Information.</p>
            </div>
            
            <div className="txt_section">
                <h3 className="sub_title">Storage and Processing Location</h3>
                <p>The Platform is owned and operated by Jus-Pharma in the United States, but the Personal
                    Information you provide may be accessible to our affiliates, vendors and suppliers in other countries.
                    If you are visiting the Website from a country other than the United States, your Personal
                    Information collected through the Platform will be transferred outside of your country. The level of
                    legal protection for Personal Information is not the same in all countries and the laws may be more
                    protective in your country than in countries where we process your information; however, we will
                    take reasonable efforts and security measures as described in this Privacy Policy in an effort to keep
                    your Personal Information secure.</p>
                <p>If you do not consent to having your personal information processed and stored in the United States,
                    please do not provide it to us. By using the Platform, and in particular when you submit Personal
                    Information through the Platform, you consent to the collection, storage, and processing of your
                    Personal Information in the United States and in any country to which we may transfer your
                    information in the course of our business operations.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Children</h3>
                <p>The Platform is not intended or designed to attract children under the age of 16. Jus-Pharma does not
                    knowingly collect Personal Information from any person under the age of 16. If Jus-Pharma
                    becomes aware it has inadvertently collected such information, it will take steps to delete it.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Other Sites</h3>
                <p>The Platform may contain links to other sites and services not owned or operated by Jus-Pharma.
                    This Privacy Policy does not apply to those sites. You should refer to information provided directly
                    by those sites to learn about their privacy policies and data collection/distribution procedures.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">Questions</h3>
                <p>If you ever have any questions about this Privacy Policy, please contact us. We respect your rights
                    and privacy, and will be happy to answer any questions you might have. You may direct any such
                    questions to us via email at <a href="mailto:info@Jus-Pharma.com">info@Jus-Pharma.com</a> or mail at:</p>
                <p>Attn: Privacy</p>
                <p>JusGlobal LLC</p>
                <p>One World Trade Center</p>
                <p>285 Fulton Street</p>
                <p>85th Floor | Suite 8500</p>
                <p>New York City NY 10007 USA</p>
            </div>
        </Container>
  </Layout>
)

export default PrivacyPage

